
//@ts-disable
import { defineComponent } from "vue";
import { store } from "@/store";
import DeleteWorkspaceModal from "@/views/pages/index/modals/website/DeleteWorkspaceModal.vue";
import { getAll as getAllApi, testNotification as testNotificationApi } from "@scrap-x/website/src/website";

export default defineComponent({
    components: {
        DeleteWorkspaceModal
    },
    data: () => {
        return {
            submitted: false,
            testing: false,
            errorMsg: '',
            notificationType: "EMAIL",
            email: {
                address: '',
            },
            webhook: {
                url: '',
                payload: '',
                method: 'post',
                headers: [

                ] as any,
            },

            dummyPayload: JSON.stringify({
                "text": "A new update for {{{url}}} has been detected. See the difference: {{{ link }}}",
                "blocks": [
                    {
                        "type": "header",
                        "text": {
                            "type": "plain_text",
                            "text": "New changes for {{ url }}"
                        }
                    },
                    {
                        "type": "image",
                        "alt_text": "Diff Preview",
                        "image_url": "{{ preview }}"
                    }
                ]
            }, null, '\t')
        };
    },
    watch: {
        workspace: {
            handler: function () {
                if (!this.workspace || !this.workspace.action) {
                    return;
                }
                this.notificationType = this.workspace.action.type;
                this.email = this.workspace.action.email || { address: '' };
                this.webhook = this.workspace.action.webhook;
                this.webhook.headers = this.webhook.headers || [];
                if (!this.webhook.payload) {
                    this.webhook.payload = this.dummyPayload;
                }
            },
            immediate: true

        },
    },
    computed: {
        canUseBasicFeatures: function () {
            return store.getters["workspace/canUseBasicFeatures"];
        },
        workspace: function () {
            return store.getters["workspace/getActiveWorkspace"];
        },
    },
    methods: {
        addHeader() {
            this.webhook.headers.push({ key: '', value: '' });
        },
        deleteHeader(index: number) {
            this.webhook.headers.splice(index, 1);
        },
        submit: async function () {
            this.submitted = true;
            await store.dispatch('workspace/updateActiveAction', {
                type: this.notificationType,
                email: this.email,
                webhook: this.webhook,
            });
            this.submitted = false;
        },
        test: async function () {
            this.testing = false;
            this.errorMsg = '';
            this.testing = true;
            await this.submit();

            return testNotificationApi(
                store.getters["workspace/getActiveWorkspace"].workspaceId,
                '1233456',
                store.getters["getJWT"]
            ).then(() => {
                this.testing = false;
            }).catch((err) => {
                try {
                    this.errorMsg = 'Error: ' + JSON.parse(err.response.data.e).message;
                } catch (e) {
                    this.errorMsg = err.response?.data;
                }
                this.testing = false;
            });
        },
    },
    mounted() {
        //this.webhook.payload = this.dummyPayload;
    },
});


import { defineComponent, PropType } from "vue";
import draggable from 'vuedraggable'
import { store } from "@/store";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'
import { Website } from "@scrap-x/website/types/website";
import { historyIndex } from "@scrap-x/website/src/websiteHistory";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import dateFormat from "dateformat";

export default defineComponent({
    components: {
        ChevronDownIcon,
        ChevronUpIcon,
        LoadingSpinner,
        draggable
    },
    data: () => {
        return {
            selectedWebsite: {},
            mouseOver: false,
            isOpen: "",
            history: [] as any[],
            historyLoading: false,
            updatingStatus: "",
            tmpData: [] as Website[],
        };
    },
    emits: ["selectedWebsite", "selectedDefaultDate", "update:websites"],
    props: {
        websites: {
            type: Array as PropType<Website[]>,
            required: true
        },
    },
    watch: {
        websites: function (val: Website[]) {
            this.tmpData = val;
        }
    },
    computed: {
        computedWebsite: {
            get() {
                return this.websites;
            },
            set(value: Website[]) {
                this.$emit('update:websites', value);
            }
        }
    },
    methods: {
        toggleActivetWebsite: async function (website: any) {
            this.updatingStatus = website.websiteId
            website.active = !website.active;
            await store.dispatch("website/update", {
                websiteId: website.websiteId, payload:
                {
                    url: website.url,
                    transformer: website.transformer,
                    headers: website.headers || [],
                    active: website.active,
                    screenshotClickSelector: website.screenshotClickSelector,
                    listSelector: website.listSelector,
                    type: website.type,
                    dataType: website.dataType,
                }
            });
            this.updatingStatus = null;
        },
        selectHistory: async function (website: any, historyItem: any) {
            this.selectWebsite(website);
            this.$emit('selectedDefaultDate', historyItem.updatedAt);
        },
        toggle: async function (website: any) {
            if (this.isOpen == website.websiteId) {
                this.isOpen = "";
                this.history = [];
            } else {
                this.isOpen = website.websiteId;
                this.history = [];
                this.historyLoading = true;
                this.history = await historyIndex(website.websiteId, store.getters["getJWT"]);
                this.historyLoading = false;
                this.history = this.history.filter((item: any) => item.updatedAt);
                this.history = this.history.sort((a: any, b: any) => {
                    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
                });
                this.history.pop()
            }
        },
        selectWebsite: function (website: any) {
            this.$emit("selectedWebsite", website);
        },
        format: function (time: string | number) {
            return dateFormat(time, "dd.mm.yyyy");
        },
        getTagColor(tag: string) {
            if (tag === 'TEXT') {
                return 'bg-red-500';
            } else if (tag === 'SUBDOMAIN') {
                return 'bg-blue-500';
            } else if (tag === 'SSL') {
                return 'bg-green-500';
            } else if (tag === 'DATA') {
                return 'bg-yellow-500';
            }
            return 'bg-orange-500';
        }
    },
});


import { defineComponent } from "vue";
import { store } from "@/store";
import { destroy as removeApi } from "@scrap-x/workspace/src/permission";
import WebsiteConfig from "./components/WebsiteConfig.vue";
import website from "@/store/website";

export default defineComponent({
  components: {
    WebsiteConfig
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      active: false,
      error: '',
      url: "",
      headers: [
      ],
      data: {
        type: "TEXT",
        dataType: [] as any[],
        transformer: "",
        listSelector: "",
        screenshotClickSelector: "",
      } as any,
    };
  },
  computed: {
    placeholder: function () {
      if(this.data.type === 'HEALTHCHECK') {
        return 'Enter a name for the healthcheck';
      }
      return `${['SUBDOMAIN', 'SSL'].includes(this.data.type) ? 'my-company.io' : 'https://new-saas-compay.io'}`;
    },
    correctWebsiteUrl: function () {
      return this.url && (this.url.startsWith("http://") || this.url.startsWith("https://"));
    },
    canUseBasicFeatures: function () {
      return store.getters["workspace/canUseBasicFeatures"];
    },
    canUseProFeatures: function () {
      return store.getters["workspace/canUseProFeatures"];
    },
    isDisabled: function () {
      return (
        this.url == this.website.url &&
        this.data.transformer == this.website.transformer &&
        JSON.stringify(this.headers) == JSON.stringify(this.website.headers) &&
        JSON.stringify(this.data.type) == JSON.stringify(this.website.type) &&
        JSON.stringify(this.data.dataType) == JSON.stringify(this.website.dataType) &&
        this.active == this.website.active &&
        this.data.listSelector == this.website.listSelector &&
        this.data.screenshotClickSelector == this.website.screenshotClickSelector
      ) || this.submitted
    },
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
  },
  watch: {
    website: function (v) {
      this.reset()
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    goToPayment: function () {
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$router.push({ name: "payment" });
    },
    addHeader() {
      this.headers.push({ key: '', value: '' });
    },
    deleteHeader(index: number) {
      this.headers.splice(index, 1);
    },
    reset: function () {
      this.url = '';

      this.headers = [
      ];
      this.data = {
        type: "TEXT",
        dataType: [] as any[],
        transformer: "",
        listSelector: "",
        screenshotClickSelector: "",
      };
      this.active = false;
      this.error = '';
      this.submitted = false;
      if (this.website.url) {
        this.url = this.website.url;
        this.active = this.website.active;
        this.headers = JSON.parse(JSON.stringify(this.website.headers)) || [];
        this.data = {
          transformer: this.website.transformer,
          type: this.website.type,
          dataType: JSON.parse(JSON.stringify(this.website.dataType)) || [],
          listSelector: this.website.listSelector,
          screenshotClickSelector: this.website.screenshotClickSelector,
        };
      }
    },
    submit: async function () {
      this.submitted = true;
      this.error = "";

      store.dispatch("website/update", {
        websiteId: this.website.websiteId, payload: {
          url: this.url,
          ...this.data,
          active: this.active,
          headers: this.headers,
        }
      }).then(() => {
        this.submitted = false;
        const el = this.$refs["closeBtn"] as any;
        el.click();
        this.$emit("reload");
      }).catch((err: any) => {
        store.dispatch("website/loadAll");
        if (err.response && err.response.data && err.response.data.e && err.response.data.e.message) {
          this.error = err.response.data.e.message;
        } else {
          this.error = "An error has occurred and we will NOT automatically scrape your website. Please update your website: " + JSON.stringify(err.response);
        }
      });

    },
  },
});


import { defineComponent } from "vue";
import { store } from "@/store";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import { getConfig, paddleConfig } from './paddle'
import BusinessInformation from './BusinessInformation.vue'
import TransactionsView from './TransactionsView.vue'
import CheckAddressModal from './CheckAddressModal.vue'

export default defineComponent({
    components: {
        BusinessInformation,
        TransactionsView,
        CheckAddressModal
    },
    emits: ["setSelectedProductId"],
    data() {
        return {
            paddle: null as Paddle | null,
            checkoutOpened: false,
            loadingForSubscription: false,
            selectedPriceId: "",
            fetchForUpdates: 0,
        };
    },
    computed: {
        noAddress: function () {
            return !this.account.businessInformation?.address?.firstLine;
        },
        plans: function () {
            return getConfig().plans.filter((p: any) => p.active);
        },
        isPaidAccount: function () {
            return ['active', 'trialing'].includes(store.getters['workspace/isPaidAccount']);
        },
        isCanceledAt: function () {
            return store.getters['workspace/isCanceledAt'];
        },
        trailUntil: function () {
            return store.getters['workspace/trailUntil'];
        },
        getPaddlePlanNames: function () {
            return store.getters['workspace/getPaddlePlanNames'];
        },
        account: function () {
            return store.getters['workspace/getActiveWorkspace'];
        },
        getActivePriceId: function () {
            return store.getters['workspace/getActivePriceId'];
        },
    },
    watch: {
        account() {
            this.checkoutOpened = false;
        },
    },
    methods: {
        upgrade: function (productId: string) {
            this.$emit('setSelectedProductId', productId);
        },
        paddleSetup: async function () {
            this.paddle = await initializePaddle({
                environment: getConfig().env,
                token: getConfig().clientToken,
                eventCallback: (event: any) => {
                    if (event.name === 'checkout.completed') {
                        this.$gtag.event('purchase', {
                            event_category: 'Plan',
                            event_label: 'Subscribed',
                            value: event.data.totals.subtotal
                        });
                        this.checkForSubscriptionUpdate();
                    }
                }
            });
        },
        checkForSubscriptionUpdate: async function () {
            this.fetchForUpdates++;
            store.dispatch('workspace/refreshActive')
            await new Promise(resolve => setTimeout(resolve, 1000));

            if (this.getActivePriceId === this.selectedPriceId) {
                this.$router.push({ name: 'index' });
            } else {
                if (this.fetchForUpdates > 6) {
                    return alert("There was an error while upgrading your subscription. Please get in contact with us.");
                }
                setTimeout(() => {
                    this.checkForSubscriptionUpdate();
                }, 2000);
            }
        },
        openCheckout: async function () {
            this.paddle!.Checkout.open({
                items: [{ priceId: this.selectedPriceId }],
                settings: {
                    displayMode: "overlay",
                },
                customer: {
                    email: store.getters['getUserEmail'],
                    address: this.account.businessInformation.address,
                    business: this.account.businessInformation.business
                },
                customData: {
                    workspaceId: store.getters['workspace/getActiveWorkspaceId']
                },
            });
        }
    },
    mounted() {
        this.paddleSetup();
    },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "md:col-span-8 py-3 py-md-0" }
const _hoisted_3 = { class: "md:ms-3 me-3 md:inline-block my-3 md:my-0" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "md:inline-block w-[89%] relative" }
const _hoisted_7 = { class: "w-[90%] text-truncate absolute -mt-4" }
const _hoisted_8 = { class: "md:col-span-3 py-3" }
const _hoisted_9 = { class: "md:inline-block w-[103px] md:text-end pe-3 relative" }
const _hoisted_10 = { class: "md:inline-block py-2 py-md-0" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "md:col-span-1 py-md-3 md:text-end" }
const _hoisted_18 = {
  key: 0,
  class: "bg-gray-100 py-3 -mt-2 rounded-b"
}
const _hoisted_19 = {
  key: 0,
  class: "text-center"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "md:ms-8" }
const _hoisted_22 = {
  key: 1,
  class: "text-center my-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_feather_icon = _resolveComponent("feather-icon")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, {
    modelValue: _ctx.computedWebsite,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedWebsite) = $event)),
    group: "websites",
    "item-key": "websiteId"
  }, {
    item: _withCtx(({ element }) => [
      _createElementVNode("div", {
        onClick: _withModifiers(($event: any) => (_ctx.toggle(element)), ["stop"])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["md:grid grid-cols-12 gap-2 hover:bg-blue-100 my-2 my-md-0 cursor-pointer rounded-t", { 'bg-blue-100': _ctx.isOpen == element.websiteId, 'rounded-b': _ctx.isOpen !== element.websiteId }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isOpen != element.websiteId)
              ? (_openBlock(), _createBlock(_component_ChevronUpIcon, {
                  key: 0,
                  class: "md:ms-1 h-5 w-5 text-black"
                }))
              : _createCommentVNode("", true),
            (_ctx.isOpen == element.websiteId)
              ? (_openBlock(), _createBlock(_component_ChevronDownIcon, {
                  key: 1,
                  class: "md:ms-1 h-5 w-5 text-black"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.updatingStatus != element.websiteId)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    onClick: _withModifiers(($event: any) => (_ctx.toggleActivetWebsite(element)), ["stop"])
                  }, _toDisplayString(element.active ? '✅' :
                                        '[]'), 9, _hoisted_4))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, " "))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(element.url), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                class: _normalizeClass(["absolute -mt-4 right-3 rounded px-1 text-[#fff] inline-block", _ctx.getTagColor(element.type)])
              }, _toDisplayString(element.type), 3)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (element.type == 'SSL')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createTextVNode("   "),
                    (element.expiresAt)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Expires At " + _toDisplayString(_ctx.format(element.expiresAt)), 1))
                      : _createCommentVNode("", true)
                  ]))
                : (element.type == 'HEALTHCHECK')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (element.lastHealthCheck)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Last Ping " + _toDisplayString(_ctx.format(element.lastHealthCheck)), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_15, "No Ping until now"))
                    ]))
                  : (element.updatedAt)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, " Last Changes " + _toDisplayString(_ctx.format(element.updatedAt)), 1))
                    : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_feather_icon, {
              type: "edit",
              class: "cursor-pointer me-2",
              size: "14",
              onClick: _withModifiers(($event: any) => (_ctx.selectWebsite(element)), ["stop"]),
              "data-bs-toggle": "modal",
              "data-bs-target": "#updateWebsiteModal"
            }, null, 8, ["onClick"]),
            _createVNode(_component_feather_icon, {
              type: "trash",
              class: "cursor-pointer me-3",
              size: "14",
              onClick: _withModifiers(($event: any) => (_ctx.selectWebsite(element)), ["stop"]),
              "data-bs-toggle": "modal",
              "data-bs-target": "#deleteWebsiteModal"
            }, null, 8, ["onClick"])
          ])
        ], 2),
        (_ctx.isOpen == element.websiteId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              (_ctx.historyLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_loading_spinner, { class: "scale-50 mt-2" })
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history, (historyItem) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: historyItem.updatedAt,
                  class: "hover:bg-blue-100 pointer p-2 cursor-pointer",
                  onClick: _withModifiers(($event: any) => (_ctx.selectHistory(element, historyItem)), ["stop"]),
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#viewWebsiteModal"
                }, [
                  _createElementVNode("div", _hoisted_21, "Show changes from " + _toDisplayString(_ctx.format(historyItem.updatedAt)), 1)
                ], 8, _hoisted_20))
              }), 128)),
              (!_ctx.historyLoading && !_ctx.history.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, " No changes have been detected yet. "))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
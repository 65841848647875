import { createApp } from 'vue'
import App from './App.vue'
import AmplifyVue from '@aws-amplify/ui-vue';
import "@aws-amplify/ui-vue/styles.css";
import '@/assets/scss/style.scss'
import { Amplify } from "aws-amplify";
import { getCognitoConfiguration } from '@scrap-x/auth/customer';
import VueFeather from 'vue-feather';
import "bootstrap/dist/js/bootstrap.js"
import Vue3Progress from "vue3-progress";
import router from './router'
import { store, key } from './store'
import { setProgressInstance } from './progress';
import VueGtag from 'vue-gtag';

// language translator for amplify-ui
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';
I18n.putVocabularies(translations);
I18n.setLanguage('en');

declare global {
    interface Window { editor: any; }
}

window.editor = window.editor || {};

Amplify.configure({
    Auth: {
        region: getCognitoConfiguration().CognitoRegion,
        userPoolId: getCognitoConfiguration().CognitoUserPoolId,
        userPoolWebClientId: getCognitoConfiguration().CognitoUserPoolClientId,
        clientMetadata: { platformName: 'Scrapx' },
        cookieStorage: {
            domain: window.location.hostname.split('.').slice(1).join('.') || window.location.hostname,
            secure: false,
            path: '/',
            expires: 5,
        },
        oauth: {
            domain: `${getCognitoConfiguration().CognitoUserPoolDomain}.auth.${getCognitoConfiguration().CognitoRegion}.amazoncognito.com`,
            scope: ['email', 'openid'],
            redirectSignIn: location.origin,
            redirectSignOut: `${location.origin}#logout`,
            responseType: 'code'
        }
    }
});


const progressOptions = {
    position: "fixed",
    height: "3px",
    color: "#2295ff",
};

const app = createApp(App);

app.use(store)
    .use(Vue3Progress, progressOptions)
    .use(store, key)
    .use(router)
    .use(AmplifyVue)
    .use(VueGtag, {
        config: { id: 'G-WYHP7HYW7Q' }
      }, router)
    .component('FeatherIcon', VueFeather)
    .mount('#app');

setProgressInstance(app);
app.provide('progress', 'progress33');